import styled from "styled-components"

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`

export const SpreadCounterWrapper = styled.div`
  overflow: visible;

  .highcharts-root {
    overflow: visible;
  }
  .highcharts-container {
    overflow: visible !important;
  }
  .chartContainer{
    overflow: visible !important;
  }
`
