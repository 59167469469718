import { Alert, Button } from "antd"
import styled from "styled-components"
import * as T from "../components/typography"
import { colors } from "../styles"

export const AlertStyled = styled(Alert)`
  > .ant-alert-icon {
    color: ${colors.iOrange};
  }

  * .ant-btn-ghost:hover {
    border-color: ${colors.iOrange};
    color: ${colors.iOrange};
  }

  * .ant-btn-ghost:focus {
    border-color: ${colors.iOrange};
    color: ${colors.iOrange};
  }

  * .ant-btn-ghost:active {
    border-color: ${colors.iRedText};
    color: ${colors.iRedText};
  }
`

export const DisconnectAlertText = styled(T.P2)`
  color: ${colors.iBlack};
`

export const AlarmStyled = styled(Alert)<{
  isSevere: boolean
}>`
  background-color: ${props =>
    props.isSevere ? colors.iRed : colors.iOrange};
`

export const AlarmTextStyled = styled(T.P2)<{
  isSevere: boolean
}>`
  padding-right: 5px;
  padding-left: 5px;
  color: ${props => (props.isSevere ? colors.iWhite : colors.iBlack)};
`

export const ButtonWFT = styled(Button)`
  background-color: ${colors.iPrimaryBlue};
`