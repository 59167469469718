import { Input, Space } from "antd"
import type { ColumnType } from "antd/es/table"
import { FilterValue } from "antd/es/table/interface"
import axios from "axios"
import { pairList } from "../../constants"
import { getPairFromId } from "../../helpers"
import { Currency, OrderBlotterDeal, OrderBlotterParsedDeal } from "../../types"
import { SearchIcon, StyledButton } from "./styled"

const capitalizeCurrency = (currency: Currency): Uppercase<Currency> =>
  currency.toUpperCase() as Uppercase<Currency>

const capitalizeFirstLetter = (oper: string) =>
  `${oper.charAt(0).toUpperCase()}${oper.slice(1)}`

const capitalizeFirstLetterBuySell = (oper: "buy" | "sell"): "Buy" | "Sell" =>
  capitalizeFirstLetter(oper) as "Buy" | "Sell"

const parseDeal = (deal: OrderBlotterDeal): OrderBlotterParsedDeal => ({
  ...deal,
  pair: getPairFromId(deal.pair).label,
  sent_time: new Intl.DateTimeFormat("en-GB", {
    day: "numeric",
    month: "numeric",
    year: "numeric",

    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    fractionalSecondDigits: 3,
  }).format(deal.sent_time),
  currency: capitalizeCurrency(deal.currency),
  operation: capitalizeFirstLetterBuySell(deal.operation),
  rate: deal.rate.toFixed(getPairFromId(deal.pair).bpPrecision || 5),
})

export const getFilteredOrderBlotterData = (
  token: string,
  filters: Record<string, FilterValue | null>,
  cursor?: { id: number; eldestDate: number }
): Promise<OrderBlotterParsedDeal[]> =>
  axios
    .get<OrderBlotterDeal[]>(
      `${process.env.REACT_APP_ENDPOINT}/order-blotter`,
      {
        headers: {
          Authorization: token,
        },
        params: {
          filterParams: JSON.stringify(pairToPairId(filters)),
          ...(cursor
            ? { cursor: cursor.id, oldestTimestamp: cursor.eldestDate }
            : {}),
        },
      }
    )
    .then(res =>
      res.data.sort((a, b) => b.sent_time - a.sent_time).map(parseDeal)
    )

export const getColumnSearchProps = (
  dataIndex: keyof Omit<OrderBlotterParsedDeal, "id" | "saved_at">
): ColumnType<OrderBlotterParsedDeal> => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => {
          setSelectedKeys(e.target.value ? [e.target.value] : [])
          confirm({ closeDropdown: false })
        }}
        onPressEnter={() => confirm()}
        style={{ marginBottom: 8, display: "block" }}
      />
      <Space>
        <StyledButton
          type="primary"
          icon={<SearchIcon />}
          size="small"
          onClick={() => confirm()}
        >
          <span className={"btn-label"}> Search</span>
        </StyledButton>
        <StyledButton
          onClick={() => {
            clearFilters && clearFilters()
            confirm()
          }}
          size="small"
        >
          Reset
        </StyledButton>
      </Space>
    </div>
  ),
  filterIcon: (filtered: boolean) => <SearchIcon filtered={filtered} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .startsWith((value as string).toLowerCase().trim()),
})

export const filterAndPrependIncomingDeals = (
  order: OrderBlotterDeal,
  dataSource: OrderBlotterParsedDeal[],
  filterParams: Record<string, FilterValue | null>
) => {
  const parsedDeal = parseDeal(order)
  const filteredDeal = [parsedDeal]
    .filter(filterByInternalId(filterParams))
    .filter(filterByDealId(filterParams))
    .filter(filterByBroker(filterParams))
    .filter(filterByPair(filterParams))
    .filter(filterByOperation(filterParams))
  return [...filteredDeal, ...dataSource]
}

const pairToPairId = (filterParams: Record<string, FilterValue | null>) => {
  const { pair } = filterParams
  if (!pair) return filterParams
  return {
    ...filterParams,
    pair: pair.map(
      pairLabel => pairList.find(({ label }) => label === pairLabel)?.id
    ),
  }
}

const filterByInternalId =
  (filterParams: Record<string, FilterValue | null>) =>
  ({ deal_id }: OrderBlotterParsedDeal) =>
    filterParams?.deal_id
      ? filterParams?.deal_id.some(filterKey =>
          deal_id.startsWith(filterKey as string)
        )
      : true

const filterByDealId =
  (filterParams: Record<string, FilterValue | null>) =>
  ({ internal_id }: OrderBlotterParsedDeal) =>
    filterParams?.internal_id
      ? filterParams?.internal_id.some(filterKey =>
          internal_id.startsWith(filterKey as string)
        )
      : true

const filterByBroker =
  (filterParams: Record<string, FilterValue | null>) =>
  ({ broker }: OrderBlotterParsedDeal) =>
    filterParams?.broker ? filterParams?.broker.includes(broker) : true

const filterByPair =
  (filterParams: Record<string, FilterValue | null>) =>
  ({ pair }: OrderBlotterParsedDeal) =>
    filterParams?.pair ? filterParams?.pair.includes(pair) : true

const filterByOperation =
  (filterParams: Record<string, FilterValue | null>) =>
  ({ operation }: OrderBlotterParsedDeal) =>
    filterParams?.operation ? filterParams?.operation.includes(operation) : true

export const transformSentDateTime = (value: string) => {
  const formattedDate = value.split("/")
  const eventDate = new Date(
    [formattedDate[1], formattedDate[0], formattedDate[2]].join("/")
  )
  return new Date(eventDate.getTime())
    .toISOString()
    .replace(/T/, "_")
    .replace(/-/g, ".")
    .replace(/Z/, "")
}
