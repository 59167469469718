import { Check } from "@styled-icons/boxicons-regular/Check"
import { CloseOutline as Cross } from "@styled-icons/evaicons-outline/CloseOutline"
import { Clock } from "@styled-icons/fa-regular/Clock"
import styled from "styled-components"
import { ReactComponent as Hexagon } from "../../icons/hexagon.svg"
import { colors } from "../../styles"
import * as T from "../typography"
import { Handshake } from "styled-icons/fa-regular"

export const WrapperMonitoringCard = styled.div<{ isLoading: boolean }>`
  background-color: ${colors.iUltraDarkGrey};
  border-radius: 4px;
  width: 100%;
  min-width: 450px;
  height: 100%;
  min-height: 250px;
  padding: 20px;
  ${({ isLoading }) =>
    isLoading
      ? `display: flex;
      align-items: center;
      justify-content: center;`
      : ``}
`

export const TitleMonitoringWrapper = styled.div`
  text-align: center;
  width: 100%;
  padding-bottom: 8px;
  margin-bottom: 9px;
  color: ${colors.iWhite};
  border-bottom: 1px solid ${colors.iMediumGrey};
`

export const TitleMonitoringCard = styled(T.H3)``

export const TableMonitoringCard = styled.div`

`

export const HeaderMonitoringCard = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 2fr 3fr;
  grid-column-gap: 15px;
  margin-bottom: 1.5em;
`

export const HeaderLabelMonitoringCard = styled(T.H4)`
  color: ${colors.iWhite};
  text-align: center;
  padding-top: 0.2em;
`

export const RowMonitoringCard = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 2fr 3fr;
  grid-column-gap: 15px;
  padding: 0.2em;
  height: 1.7em;
  align-items: center;
  :hover {
    background-color: ${colors.iDarkGrey};
    transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`

export const RowValueImgMonitoringCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const HexagonStyled = styled(Hexagon)<{ $isUsed?: boolean }>`
  color: ${({ $isUsed }) => ($isUsed ? `${colors.iGreen}` : `${colors.iRed}`)};
  width: 12px;
`

export const HexagonColored = styled(Hexagon)<{ color: string }>`
  color: ${({ color }) => color};
  width: 12px;
`

export const RowValueLabelMonitoringCard = styled(T.P3)`
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${colors.iWhite};
  text-align: center;
`

export const NotesWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
`

export const StyledClock = styled(Clock)<{ color: string }>`
  color: ${({ color }) => color};
`

export const StyledCheck = styled(Check)`
  color: ${colors.iGreenText};
`

export const StyledCross = styled(Cross)`
  color: ${colors.iRedText};
`

export const HandshakeStyled = styled(Handshake)`
  color: ${colors.iOrange};
`
