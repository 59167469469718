import HighchartsReact from "highcharts-react-official"
import * as Highcharts from "highcharts/highstock"
import React from "react"
import { colors } from "../../styles"

function tooltipPointFormatter(
  this: Highcharts.TooltipFormatterContextObject,
  _: Highcharts.Tooltip,
) {
  if (this.y === undefined || this.y === null) return ""
  return (
    '<div style="line-height: 12px;white-space: nowrap;color:#e9e9ec;">' +
    `<span style="color: ${(this.series as any).color};">&#11044</span>` +
    `<span style="color:#e9e9ec;"> ${this.series.name}: <b>` +
    this.y.toLocaleString("en", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      currencyDisplay: "narrowSymbol",
    }) +
    "</b></span>" +
    "</div><br />"
  )
}

type BalanceEquityMemoizedGraphProps = {
  chartComponentRef: React.RefObject<HighchartsReact.RefObject>
  loadedHistorical: boolean
}

const BalanceEquityMemoizedGraph = ({
  chartComponentRef,
  loadedHistorical,
}: BalanceEquityMemoizedGraphProps) => (
  <HighchartsReact
    highcharts={Highcharts}
    constructorType={"StockChart"}
    options={{
      accessibility: {
        enabled: false,
      },
      boost: {
        usePreallocated: false,
        allowForce: true,
        enabled: true,
      },
      chart: {
        backgroundColor: colors.iUltraDarkGrey,
        spacingLeft: 0,
        spacingRight: 0,
      },
      ...(loadedHistorical
        ? {
            navigator: {
              enabled: true,
              height: 25,
            },
            rangeSelector: {
              adaptToUpdatedData: true,
              enabled: true,
              stickToMax: true,
              inputPosition: {
                align: "right",
              },
              buttons: [
                {
                  type: "minute",
                  count: 30,
                  text: "30m",
                },
                {
                  type: "hour",
                  count: 1,
                  text: "1h",
                },
                {
                  type: "hour",
                  count: 6,
                  text: "6h",
                },
                {
                  type: "hour",
                  count: 12,
                  text: "12h",
                },
                {
                  type: "day",
                  count: 1,
                  text: "1d",
                },
                {
                  type: "day",
                  count: 2,
                  text: "2d",
                },
                {
                  type: "all",
                  text: "All",
                },
              ],
              selected: 1,
            },
            scrollbar: {
              enabled: true,
            },
          }
        : {
            navigator: {
              enabled: false,
              height: 25,
            },
            rangeSelector: {
              enabled: false,
            },
            scrollbar: {
              enabled: false,
            },
          }),
      xAxis: {
        gridLineWidth: 0,
        lineColor: colors.iDarkGrey,
        tickWidth: 0,
        type: "datetime",
        ordinal: false,
      },
      yAxis: [
        {
          opposite: false,
          endOnTick: false,
          gridLineColor: colors.iDarkGrey,
          plotLines: [
            {
              value: 0,
              color: colors.iGrey01,
              width: 1,
              zIndex: 5,
            },
          ],
        },
        {
          gridLineColor: colors.iDarkGrey,
          linkedTo: 0,
          endOnTick: false,
          opposite: true,
        },
      ],
      plotOptions: {
        series: {
          dataGrouping: {
            enabled: false,
          },
          boostThreshold: 1,
          animation: false,
          enableMouseTracking: true,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
      tooltip: {
        useHtml: true,
        backgroundColor: colors.iBlack,
        borderColor: colors.iBlack,
        headerFormat: '<span style="color:#e9e9ec;"><b>{point.key}</b></span>',
        pointFormatter: tooltipPointFormatter,
        valueDecimals: 0,
        shared: true,
        xDateFormat: "%a, %b %e, %H:%M:%S",
        split: true,
      },
    }}
    ref={chartComponentRef}
    containerProps={{
      className: "chartContainer",
      style: {
        display: "flex",
        width: "100%",
        height: "300px",
      },
    }}
  />
)

export default React.memo(BalanceEquityMemoizedGraph)
