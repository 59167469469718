import { StyledNotes } from "./styled"

export const LetterIcon =
  (letter: string) =>
  (
    args: React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >
  ) =>
    (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        {...args}
      >
        <StyledNotes>{letter}</StyledNotes>
      </div>
    )
