import { MagnifyingGlass } from "@styled-icons/foundation/MagnifyingGlass"
import { Fragment, useCallback, useEffect, useMemo, useState } from "react"
import { UserAddOutline } from "styled-icons/typicons"
import {
  changeGraphComm,
  changeGraphVWAP,
  formatVolume,
  getBrokerPair,
  getPairFromId,
} from "../../helpers"
import { colors } from "../../styles"
import {
  GraphInfo,
  GraphReactComponent,
  LiquidityBook as LiquidityBookType,
  LiquidityBookTick,
  Quote,
} from "../../types"
import { LetterIcon } from "../letter-icon"
import * as T from "../typography"
import WidgetHeader from "../widget-header"
import { WidgetSubheader } from "../widget-subheader"
import { WidgetWrapper } from "../widget-wrapper"
import { trimBrokerName } from "./helpers"
import {
  ColumnLabels,
  ContentWrapper,
  ContributorsExtras,
  ContributorsNames,
  ContributorsWrapper,
  FirstPriceWrapper,
  GreyLine,
  Price,
  PriceWrapper,
  QuotesWrapper,
  Spread,
  Volume,
  VolumeWrapper,
} from "./styled"

const setMarketDepth = (n: number, quotes: Quote[], consolidated = true) => {
  const marketDepth = quotes.length
  if (marketDepth === n) return quotes
  if (marketDepth > n) return quotes.slice(0, n)
  const padding: Quote = {
    bid: {
      qty: -1,
      price: -1,
      brokers: consolidated ? [] : undefined,
    },
    ask: {
      qty: -1,
      price: -1,
      brokers: consolidated ? [] : undefined,
    },
  }

  return [...quotes, ...new Array(n - marketDepth).fill(padding)]
}

const maxNumberOfBrokers = 3

const LiquidityBook: GraphReactComponent = ({
  socket,
  broker,
  pair,
  graphs,
  setGraphs,
  id,
  vwap,
  comm,
}) => {
  const [liquidityBook, setLiquidityBook] = useState<LiquidityBookType>({
    pair: getPairFromId(pair),
    quotes: setMarketDepth(vwap ? 1 : 3, [], broker.id === "aggregated"),
  })
  const [spread, setSpread] = useState<string>("")
  const [showContributors, setShowContributors] = useState(false)
  const [showAllBrokers, setShowAllBrokers] = useState<boolean>(false)

  const graphInfo: GraphInfo = useMemo(
    () => ({
      broker,
      pair,
      type: "liquidity",
      id,
      Graph: LiquidityBook,
      vwap,
      comm,
    }),
    [broker, comm, id, pair, vwap],
  )

  const precision = useMemo(
    () =>
      getPairFromId(liquidityBook.pair.id).bpPrecision + (comm || vwap ? 1 : 0),
    [comm, liquidityBook.pair.id, vwap],
  )

  const spreadPrecision = useMemo(
    () => getPairFromId(liquidityBook.pair.id).bpPrecision,
    [liquidityBook.pair.id],
  )

  const priceHighlight = useMemo(
    () => (comm || vwap ? ([-4, -2] as const) : ([-3, -1] as const)),
    [comm, vwap],
  )

  const liquidityListener = useCallback(
    (tick: LiquidityBookTick) => {
      const brokerPair = getBrokerPair(broker, pair, comm, vwap)

      if (tick.brokerPair === brokerPair) {
        setLiquidityBook({
          broker: tick.broker,
          quotes: setMarketDepth(vwap ? 1 : 3, tick.data),
          pair: getPairFromId(tick.pair),
        })
        const spread =
          tick.data[0].ask && tick.data[0].bid
            ? tick.data[0].ask.price - tick.data[0].bid.price
            : 0

        const spreadWithDigits = (spread * 10 ** (spreadPrecision - 1)).toFixed(
          comm || vwap ? 2 : 1,
        )
        setSpread(spreadWithDigits)
      }
    },
    [broker, comm, pair, spreadPrecision, vwap],
  )

  useEffect(() => {
    if (!socket) return () => {}
    socket.on(showAllBrokers ? "liquidity-all" : "liquidity", liquidityListener)

    return () =>
      socket.off(
        showAllBrokers ? "liquidity-all" : "liquidity",
        liquidityListener,
      )
  }, [socket, graphs, showAllBrokers, liquidityListener])

  const TOB = liquidityBook.quotes[0]

  return (
    <WidgetWrapper>
      <WidgetHeader
        graphs={graphs}
        graphInfo={graphInfo}
        setGraphs={setGraphs}
        showGraphsButton={true}
        setShowContributors={setShowContributors}
        showContributors={showContributors}
        widgetTitle="Liquidity Book"
      />
      <WidgetSubheader
        graphs={graphs}
        graphInfo={graphInfo}
        setGraphs={setGraphs}
        options={[
          {
            type: "toggle",
            value: vwap || false,
            onClick: changeGraphVWAP(graphs, setGraphs, graphInfo),
            Icon: LetterIcon("VW"),
            title: showContributors
              ? "Show prices bands"
              : "Weighted clip size price",
          },
          {
            type: "toggle",
            value: comm || false,
            onClick: changeGraphComm(graphs, setGraphs, graphInfo),
            Icon: LetterIcon("C"),
            title: comm
              ? "Do not consider commission"
              : "Factor commission in prices",
          },
          ...(broker.id === "aggregated"
            ? [
                {
                  type: "toggle" as "toggle",
                  value: showAllBrokers,
                  onClick: () => setShowAllBrokers(oldState => !oldState),
                  Icon: UserAddOutline,
                  title: showAllBrokers
                    ? "Only brokers in prod"
                    : "All brokers",
                },
                {
                  type: "toggle" as "toggle",
                  value: showContributors,
                  onClick: () => setShowContributors(oldState => !oldState),
                  Icon: MagnifyingGlass,
                  title: showContributors
                    ? "Hide contributors"
                    : "Show contributors",
                },
              ]
            : []),
        ]}
      />
      <ContentWrapper>
        <ColumnLabels>
          <T.Notes>BID</T.Notes>
          <Spread>{spread}</Spread>
          <T.Notes>ASK</T.Notes>
        </ColumnLabels>

        <QuotesWrapper>
          <VolumeWrapper type="bid">
            <T.Notes style={{ color: colors.iMediumGrey }}>SIZE</T.Notes>
            <Volume isTOB>{formatVolume(TOB.bid?.qty ?? -1)}</Volume>
          </VolumeWrapper>

          {(["bid", "ask"] as const).map(type => [
            <FirstPriceWrapper
              key={`firstpricewrapper-${type}-${graphInfo.pair}-${graphInfo.broker}`}
              type={type}
            >
              <PriceWrapper>
                <Price>
                  {TOB[type]?.price
                    ?.toFixed(precision)
                    ?.slice(0, priceHighlight[0]) ?? -1}
                </Price>
                <Price isPip isTOB>
                  {TOB[type]?.price
                    ?.toFixed(precision)
                    ?.slice(priceHighlight[0], priceHighlight[1]) ?? -1}
                </Price>
                <Price>
                  {TOB[type]?.price
                    ?.toFixed(precision)
                    ?.slice(priceHighlight[1]) ?? -1}
                </Price>
              </PriceWrapper>
            </FirstPriceWrapper>,
          ])}

          <VolumeWrapper type="ask">
            <T.Notes style={{ color: colors.iMediumGrey }}>SIZE</T.Notes>
            <Volume isTOB>{formatVolume(TOB.ask?.qty ?? -1)}</Volume>
          </VolumeWrapper>

          {showContributors &&
            (["bid", "ask"] as const).map(type => (
              <ContributorsWrapper
                key={`contributors-${type}-${graphInfo.pair}-${graphInfo.broker}`}
              >
                <ContributorsNames>
                  {TOB[type]?.brokers
                    ?.slice(0, maxNumberOfBrokers)
                    ?.map(trimBrokerName)
                    ?.sort()
                    ?.join(", ")}
                </ContributorsNames>
                <ContributorsExtras>
                  {TOB[type]?.brokers?.length &&
                  TOB[type]!.brokers!.length - maxNumberOfBrokers > 0
                    ? "+" + (TOB[type]!.brokers!.length - maxNumberOfBrokers)
                    : ""}
                </ContributorsExtras>
              </ContributorsWrapper>
            ))}

          <GreyLine />

          {liquidityBook.quotes.slice(1).map((quote: Quote, index: number) => (
            <Fragment
              key={`quotes-${graphInfo.pair}-${graphInfo.broker}-${index}-${graphInfo.id}`}
            >
              <VolumeWrapper type="bid">
                <Volume>{formatVolume(quote.bid?.qty ?? -1)}</Volume>
              </VolumeWrapper>
              {(["bid", "ask"] as const).map(type => (
                <PriceWrapper
                  key={`pricewrapper-${type}-${graphInfo.pair}-${graphInfo.broker}-${index}`}
                >
                  <Price isPip>
                    {quote[type]?.price
                      ?.toFixed(precision)
                      ?.slice(priceHighlight[0], priceHighlight[1]) ?? -1}
                  </Price>
                  <Price>
                    {quote[type]?.price
                      ?.toFixed(precision)
                      ?.slice(priceHighlight[1]) ?? -1}
                  </Price>
                </PriceWrapper>
              ))}
              <VolumeWrapper type="ask">
                <Volume>{formatVolume(quote.ask?.qty ?? -1)}</Volume>
              </VolumeWrapper>

              {showContributors &&
                (["bid", "ask"] as const).map(type => (
                  <ContributorsWrapper
                    key={`contributors-${type}-${graphInfo.pair}-${graphInfo.broker}`}
                  >
                    <ContributorsNames>
                      {quote[type]?.brokers
                        ?.slice(0, maxNumberOfBrokers)
                        ?.map(trimBrokerName)
                        ?.sort()
                        ?.join(", ")}
                    </ContributorsNames>
                    <ContributorsExtras>
                      {quote[type]?.brokers?.length &&
                      quote[type]!.brokers!.length - maxNumberOfBrokers > 0
                        ? "+" +
                          (quote[type]!.brokers!.length - maxNumberOfBrokers)
                        : ""}
                    </ContributorsExtras>
                  </ContributorsWrapper>
                ))}

              <GreyLine />
            </Fragment>
          ))}
        </QuotesWrapper>
      </ContentWrapper>
    </WidgetWrapper>
  )
}

export default LiquidityBook
