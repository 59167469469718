import { typedKeys } from "../../helpers"
import { BrokerPair, PenalizationDataType } from "../../types"

export const reshapeAllData = (
  oldData: PenalizationDataType,
  newValues: {
    lastUpdate: number
    data: Partial<Record<BrokerPair, number>>
  },
) => ({
  ...oldData,
  lastUpdate: newValues.lastUpdate,
  penalizationsData: typedKeys(newValues.data).reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        ...oldData.penalizationsData[key as BrokerPair],
        expected: newValues.data[key],
      },
    }),
    oldData.penalizationsData,
  ),
})

export const reshapeDefaultValue = (value: Record<BrokerPair, {
  aggregated: number;
  expected: number;
}>) => typedKeys(value).reduce(
  (acc, val) => ({
    ...acc,
    [val]: value[val].expected,
  }),
  {},
)