import HighchartsReact from "highcharts-react-official"
import * as HighchartsStock from "highcharts/highstock"
import HighchartsAnnotations from "highcharts/modules/annotations"
import React from "react"
import { colors } from "../../styles"
import { getMaxRange, labelToPipFormatter, zoomButtons } from "./helpers"

HighchartsAnnotations(HighchartsStock)

const MemoizedSpreadGraph = ({
  chartComponentRef,
  precision,
  chunkSizeMinutes,
  setExtremes,
}: {
  chartComponentRef: React.RefObject<HighchartsReact.RefObject>
  precision: number
  chunkSizeMinutes: number
  setExtremes: HighchartsStock.AxisSetExtremesEventCallbackFunction
}) => (
  <HighchartsReact
    highcharts={HighchartsStock}
    constructorType={"StockChart"}
    containerProps={{
      className: "chartContainer",
      style: {
        height: "100%",
        width: "100%",
      },
    }}
    options={{
      accessibility: {
        enabled: false,
      },
      boost: {
        enabled: false,
        allowForce: false,
        useGPUTranslations: true,
        usePreallocated: true,
      },
      chart: {
        backgroundColor: colors.iUltraDarkGrey,
        spacingLeft: 0,
        spacingRight: 0,
      },
      navigator: {
        enabled: true,
        height: 25,
      },
      rangeSelector: {
        adaptToUpdatedData: true,
        enabled: true,
        stickToMax: true,
        inputPosition: {
          align: "right",
        },
        buttons: zoomButtons(chunkSizeMinutes),
      },
      scrollbar: {
        enabled: true,
      },
      xAxis: {
        gridLineWidth: 0,
        lineColor: colors.iDarkGrey,
        tickWidth: 0,
        type: "datetime",
        ordinal: false,
        minRange: 1_000 * 60 * 15, // 15 minutes
        maxRange: getMaxRange(chunkSizeMinutes),
        events: {
          afterSetExtremes: setExtremes,
        },
      },
      yAxis: [
        {
          opposite: false,
          gridLineColor: colors.iDarkGrey,
          labels: {
            formatter: labelToPipFormatter(precision),
          },
        },
        {
          labels: {
            x: -3,
            align: "right",
            reserveSpace: true,
            formatter: labelToPipFormatter(precision),
          },
          gridLineColor: colors.iDarkGrey,
          linkedTo: 0,
          opposite: true,
          offset: 15,
        },
      ],
      plotOptions: {
        series: {
          animation: false,
          enableMouseTracking: false,
          dataGrouping: {
            enabled: false,
          },
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
      series: [
        {
          animation: false,
          type: "candlestick",
          showInNavigator: true,
          zIndex: 6,
          enableMouseTracking: true,
          lineColor: colors.iMediumGrey,
          upLineColor: `${colors.iBlue00}80`,
          color: `${colors.iUltraDarkGrey}80`,
          upColor: `${colors.iBlue00}80`,
          turboThreshold: 0,
        },
      ],
      tooltip: {
        enabled: true,
        animation: false,
        backgroundColor: colors.iUltraDarkGrey,
        borderColor: colors.iUltraDarkGrey,
        style: {
          color: colors.iWhite,
        },
        distance: 20,
        formatter: function (this: any) {
          const { open, close, high, low, x, custom } =
            this.points.at(0).point.options

          const { buySize, sellSize, tradeSize, avgPriceBuy, avgPriceSell } =
            custom
          if (
            typeof x !== "number" ||
            typeof open !== "number" ||
            typeof close !== "number" ||
            typeof high !== "number" ||
            typeof low !== "number"
          )
            return

          if (
            typeof buySize !== "number" ||
            typeof sellSize !== "number" ||
            typeof tradeSize !== "number" ||
            typeof avgPriceBuy !== "number" ||
            typeof avgPriceSell !== "number"
          )
            return

          return `
          ${HighchartsStock.dateFormat("%a, %b %e, %H:%M", x)}
          <br>
          <br><b>Open:</b>  ${open.toFixed(
            precision
          )}<br><b>Close:</b>  ${close.toFixed(
            precision
          )}<br><b>High:</b>  ${high.toFixed(
            precision
          )}<br><b>Low:</b>  ${low.toFixed(precision)}
          <br>
          <br><b>Buy size:</b>  ${buySize.toLocaleString("en")}
          <br><b>Sell size:</b>  ${sellSize.toLocaleString("en")}
          <br><b>Traded volume:</b>  ${tradeSize.toLocaleString("en")}
          <br>
          <br><b>Avg Price Buy:</b>  ${
            avgPriceBuy ? avgPriceBuy.toFixed(precision) : 0
          }
          <br><b>Avg Price Sell:</b>  ${
            avgPriceSell ? avgPriceSell.toFixed(precision) : 0
          } 
          `
        },
        headerFormat: "",
      },
    }}
    ref={chartComponentRef}
  />
)
export default React.memo(MemoizedSpreadGraph)
