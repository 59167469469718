import axios from "axios"
import { ReactSetter, SavedLayoutsType } from "../../types"

export const deleteLocalLayoutByName = (
  name: string,
  updateSavedLayouts: ReactSetter<SavedLayoutsType>
) =>
  updateSavedLayouts(oldLayouts =>
    (oldLayouts || []).filter(layout => layout.name !== name)
  )

export const deleteRemoteLayoutByName = (
  userToken: string,
  url: string,
  layoutName: string
) =>
  axios.delete(url, {
    data: {
      userToken,
      layoutName,
    },
    headers: {
      Authorization: userToken,
    },
  })
