import React, { useCallback, useEffect, useMemo, useState } from "react"
import { pairList } from "../../constants"
import { getBrokerPair, getPairFromId } from "../../helpers"
import {
  GraphInfo,
  GraphReactComponent,
  OpenPositionsData,
  OpenPositionsTick,
} from "../../types"
import WidgetHeader from "../widget-header"
import { WidgetSubheader } from "../widget-subheader"
import { WidgetWrapper } from "../widget-wrapper"
import { getIsCurrentPriceUnavailable } from "./helpers"
import {
  OpenPositionsGridColored,
  OpenPositionsGridHeader,
  OpenPositionsGridLabel,
  OpenPositionsGridPairLabel,
  OpenPositionsGridWrapper,
} from "./styled"

const columnLabels = [
  "PAIR",
  "SIZE (BASE)",
  "AVG PRICE",
  "CURRENT",
  "PNL (USD)",
  "PNL (PIPS)",
]

const OpenPositions: GraphReactComponent = ({
  socket,
  broker,
  pair,
  graphs,
  setGraphs,
  id,
  vwap,
  comm,
}) => {
  const [openPositionsData, setOpenPositionsData] = useState<
    OpenPositionsData[]
  >([])

  const openPositionsListener = useCallback(
    (tick: OpenPositionsTick) => {
      const brokerPair = getBrokerPair(broker, pair)
      if (tick.brokerPair === brokerPair)
        setOpenPositionsData(
          tick.data.sort((a, b) => (a.pair > b.pair ? +1 : -1)),
        )
    },
    [broker, pair],
  )

  useEffect(() => {
    if (!socket) return () => {}
    socket.on("openPositions", openPositionsListener)

    return () => socket.off("openPositions", openPositionsListener)
  }, [socket, graphs, openPositionsListener])

  const graphInfo: GraphInfo = useMemo(
    () => ({
      broker,
      pair,
      type: "openPositions",
      id,
      Graph: OpenPositions,
      vwap,
      comm,
    }),
    [broker, comm, id, pair, vwap],
  )

  const precision = useMemo(
    () =>
      openPositionsData.map(
        point => getPairFromId(point.pair).bpPrecision || 5,
      ),
    [openPositionsData],
  )

  return (
    <WidgetWrapper>
      <WidgetHeader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        widgetTitle="Open Positions"
      />
      <WidgetSubheader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
      />
      <OpenPositionsGridWrapper>
        {columnLabels.map(label => (
          <OpenPositionsGridHeader key={label}>{label}</OpenPositionsGridHeader>
        ))}
        {openPositionsData.length > 0 &&
          openPositionsData.map(
            ({ pair, size, avgPrice, current, pnlPip, pnlUsd }, index) => {
              const isSizeZero = size < 0.2 && size > -0.2
              const isCurrentPriceUnavailable = getIsCurrentPriceUnavailable(
                current,
                pnlPip,
                pnlUsd,
              )

              return (
                <React.Fragment key={index}>
                  <OpenPositionsGridPairLabel>
                    {pairList.find(p => p.id === pair)?.label || ""}
                  </OpenPositionsGridPairLabel>
                  <OpenPositionsGridColored
                    isSizeZero={isSizeZero}
                    isNegative={size < 0}
                    style={{ textAlign: "right" }}
                  >
                    {(size ?? 0).toLocaleString("en")}
                  </OpenPositionsGridColored>
                  <OpenPositionsGridLabel>
                    {!isSizeZero
                      ? (avgPrice ?? 0).toFixed(precision.at(index) || 5)
                      : "n/a"}
                  </OpenPositionsGridLabel>
                  <OpenPositionsGridLabel>
                    {isCurrentPriceUnavailable
                      ? "n/a"
                      : (current ?? 0).toFixed(precision.at(index) || 5)}
                  </OpenPositionsGridLabel>
                  <OpenPositionsGridColored
                    isSizeZero={isSizeZero || isCurrentPriceUnavailable}
                    isNegative={pnlUsd < 0}
                  >
                    {isCurrentPriceUnavailable
                      ? "n/a"
                      : !isSizeZero
                      ? (pnlUsd ?? 0).toLocaleString("en", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : "0.00"}
                  </OpenPositionsGridColored>
                  <OpenPositionsGridColored
                    isSizeZero={isSizeZero || isCurrentPriceUnavailable}
                    isNegative={pnlPip < 0}
                  >
                    {isCurrentPriceUnavailable
                      ? "n/a"
                      : !isSizeZero
                      ? (pnlPip ?? 0).toLocaleString("en", {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        })
                      : "0.0"}
                  </OpenPositionsGridColored>
                </React.Fragment>
              )
            },
          )}
      </OpenPositionsGridWrapper>
    </WidgetWrapper>
  )
}

export default OpenPositions
