import axios from "axios"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { AuthContext } from "../../components/auth-context"
import MonitoringDelaysCard from "../../components/monitoring-card-delays"
import MonitoringTradedPairsCard from "../../components/monitoring-card-traded-pairs"
import MonitoringUsedBrokersCard from "../../components/monitoring-card-used-brokers"
import MonitoringWebFxComparisonCard from "../../components/monitoring-card-webFx-comparison"
import { computeLayouts, handleWindowResize } from "./helpers"
import { MonitoringCardsWrapper, RGLStyled } from "./styled"

const url =
  (process.env.REACT_APP_ENDPOINT || "http://localhost:4000") +
  "/monitoring/brokers-pairs-number"

export const Monitoring = () => {
  const [rglWidth, setRglWidth] = useState<number>(600)
  const [cols, setCols] = useState<number>()
  const [loaded, setLoaded] = useState<boolean>()
  const [brokersPairsNumber, setBrokersPairsNumber] = useState<{
    brokers: number
    tradedPairs: number
    pairs: number
  }>()
  const { getCurrentUser } = useContext(AuthContext)
  const rglRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const cleanup = handleWindowResize(rglRef, setCols, setRglWidth)
    setTimeout(() => window.dispatchEvent(new Event("resize")), 50)
    return cleanup
  }, [])

  useEffect(() => {
    getCurrentUser().then(user => {
      if (!user.isLogged) return
      axios
        .get<{ brokers: number; pairs: number; tradedPairs: number }>(url, {
          headers: {
            Authorization: user.tokens.token,
          },
        })
        .then(res => {
          setBrokersPairsNumber(res.data)
        })
        .catch(console.error)
    })
  }, [getCurrentUser])

  const widgets = useMemo(
    () =>
      brokersPairsNumber
        ? [
            {
              Widget: <MonitoringUsedBrokersCard />,
              layout: { w: 13, h: (brokersPairsNumber?.brokers ?? 2) + 4 },
            },
            {
              Widget: <MonitoringTradedPairsCard />,
              layout: { w: 12, h: Math.min((brokersPairsNumber?.pairs ?? 2) + 3,20) },
            },
            {
              Widget: <MonitoringDelaysCard />,
              layout: { w: Math.max(brokersPairsNumber?.pairs / 2 ?? 13,13), h: 15 },
            },
            {
              Widget: <MonitoringWebFxComparisonCard compare="numberOfDeals" />,
              layout: { w: 9, h: (brokersPairsNumber?.tradedPairs ?? 2) + 5 },
            },
            {
              Widget: <MonitoringWebFxComparisonCard compare="balance" />,
              layout: { w: 11, h: (brokersPairsNumber?.tradedPairs ?? 2) + 5 },
            },
          ]
        : [],
    [brokersPairsNumber],
  )

  const layouts = useMemo(() => {
    if (widgets.length) {
      setLoaded(true)
      return computeLayouts(widgets, cols)
    } else return []
  }, [widgets, cols])

  return (
    <MonitoringCardsWrapper>
      {loaded && (
        <RGLStyled
          key={"grid"}
          className="layout"
          rowHeight={15}
          margin={[16, 16]}
          width={rglWidth}
          cols={cols}
          layout={layouts}
        >
          {widgets.map(({ Widget }, i) => (
            <div key={`MonitoringWidget${i}`}>{Widget}</div>
          ))}
        </RGLStyled>
      )}
    </MonitoringCardsWrapper>
  )
}
