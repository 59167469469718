import { colors } from "../../styles"
import { UsedBrokersDataType } from "../../types"
import CustomTooltip from "../tooltip"
import { HandshakeStyled, HexagonColored, StyledClock } from "./styled"

const mapDelayColor = (delay: number) => {
  if (delay < 2000) return colors.iOrange10
  else return colors.iRedText
}

const warningCheckDelay = ({
  delayMs,
  broker,
}: UsedBrokersDataType[number]) => {
  if (!delayMs) return []
  if (delayMs < 1_000) return []
  else
    return [
      <CustomTooltip
        key={`${broker}-check-delay`}
        show={true}
        title={`Largest delay for ${broker} is ${Math.round(
          delayMs || 0
        )}ms at ${new Date().toLocaleString()}.`}
        color={colors.iUltraDarkGrey}
        placement={"topLeft"}
        overlayInnerStyle={{
          borderColor: mapDelayColor(delayMs),
          borderWidth: 3,
          borderStyle: "solid",
          borderRadius: 10,
        }}
      >
        <StyledClock width={16} color={mapDelayColor(delayMs)} />
      </CustomTooltip>,
    ]
}

const POPBrokerNotInConsolidated = ({
  broker,
  POP,
  consolidated,
}: UsedBrokersDataType[number]) =>
  !consolidated && POP ? (
    <CustomTooltip
      key={`${broker}-POP`}
      show={true}
      title={`${broker} is a Prime broker, but not included in the consolidated.`}
      color={colors.iUltraDarkGrey}
      placement={"topLeft"}
      overlayInnerStyle={{
        borderColor: colors.iOrange,
        borderWidth: 3,
        borderStyle: "solid",
        borderRadius: 10,
      }}
    >
      <HexagonColored width={16} color={colors.iOrange10} />
    </CustomTooltip>
  ) : (
    []
  )

const brokerWithNoDeals = ({
  broker,
  consolidated,
  dealsReceived,
}: UsedBrokersDataType[number]) =>
  consolidated && !dealsReceived ? (
    <CustomTooltip
      key={broker}
      show={true}
      title={`We have not received any deal for the broker ${broker}`}
      color={colors.iUltraDarkGrey}
      placement={"topLeft"}
      overlayInnerStyle={{
        borderColor: colors.iOrange,
        borderWidth: 3,
        borderStyle: "solid",
        borderRadius: 10,
      }}
    >
      <HandshakeStyled width={25} />
    </CustomTooltip>
  ) : (
    []
  )

const missingTradeConf = ({
  broker,
  tradingConfReceived,
}: UsedBrokersDataType[number]) =>
  !tradingConfReceived ? `Trading conf message from ${broker} not received` : []

const receivingDealsFromNotConsolidatedBroker = ({
  broker,
  dealsReceived,
  consolidated,
}: UsedBrokersDataType[number]) =>
  !consolidated && dealsReceived
    ? `We are currently receiving deals from ${broker}, which is not included in the consolidated.`
    : []

const errorCheckReceivedData = ({
  delayMs,
  dealsReceived,
  broker,
}: UsedBrokersDataType[number]) => {
  if (!delayMs && dealsReceived)
    return [`We are receiving deals, but not quotes from ${broker}.`]
  if (!delayMs) return [`No quotes received from ${broker}.`]
  else return []
}

const warningsFunctions = [
  warningCheckDelay,
  POPBrokerNotInConsolidated,
  brokerWithNoDeals,
]
const errorsFunctions = [
  errorCheckReceivedData,
  missingTradeConf,
  receivingDealsFromNotConsolidatedBroker,
]

export const buildWarnings = (row: UsedBrokersDataType[number]) =>
  warningsFunctions.flatMap(f => f(row))

export const buildErrors = (row: UsedBrokersDataType[number]): string[] =>
  errorsFunctions.flatMap(f => f(row))

export const writeListElements = (errors: string[]) =>
  errors.map((errorText, idx) => <li key={idx}>{errorText}</li>)
