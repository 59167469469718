import _ from "lodash"
import { useCallback, useMemo, useState } from "react"
import { z } from "zod"
import { loadState, removeState, saveState } from "./localStorage"

export const useLocalStorage = <T>(
  key: string,
  initialValue: T,
  validator?: z.ZodTypeAny
) => {
  const validType = useMemo(() => {
    if (!validator) return true
    return validator.safeParse(loadState(key)).success
  }, [validator, key])

  const [storage, setStorage] = useState<T>(() => {
    const loadedState = loadState(key)
    if (!loadedState || !validType) {
      saveState(key, initialValue)
      return initialValue
    } else return loadedState
  })

  const updateStorage = useCallback(
    (newStorage: T | ((prevState: T) => T)) => {
      const valueToStore =
        newStorage instanceof Function ? newStorage(storage) : newStorage

      if (_.isEqual(valueToStore, storage)) return
      setStorage(valueToStore)
      saveState(key, valueToStore)
    },
    [key, storage]
  )

  const deleteStorage = () => {
    removeState(key)
    setStorage(initialValue)
  }

  return {
    storage,
    updateStorage,
    deleteStorage,
  }
}
