import * as T from "../../components/typography"
import { Input } from "antd"
import axios, { AxiosError } from "axios"
import { useEffect, useState } from "react"
import { LoginRegistrationError } from "../../types"
import {
  AlertStyled,
  ButtonStyled,
  PassRecoveryFormWrapper,
  PassRecoveryWrapper,
  TitlePassRecoveryForm,
} from "./styled"
import { isValidEmail } from "./helpers"

const applicationId = process.env.REACT_APP_LOGIN_APP_ID || ""
const url = process.env.REACT_APP_LOGIN_ENDPOINT || ""

const PasswordRecovery = () => {
  const [email, setEmail] = useState<string>("")
  const [error, setError] = useState<string>("")
  const [isLoading, setIsLoading] = useState<"initial" | "loading" | "done">(
    "initial"
  )

  const getAccessTokens = async (email: string) => {
    const res = await axios
      .post(`${url}/api/user/forgot-password`, {
        loginId: email,
        applicationId,
      })
      .catch((err: Error | AxiosError) => {
        if (!axios.isAxiosError(err)) return
        const axiosError = err as AxiosError<LoginRegistrationError>
        const fieldErrors = axiosError.response?.data.fieldErrors
        if (!fieldErrors) {
          throw new Error("Unknown error, please try again.")
        }

        const errorMessage = Object.values(fieldErrors)[0][0].message

        setError(
          errorMessage.replace(/\[|\]|user\./g, "").replace("loginId", "email")
        )
        return
      })
    return res?.data
  }

  const submitCredentials = async (email: string) => {
    setIsLoading("loading")
    setError("")
    await getAccessTokens(email)
      .catch(err => console.error(err))
      .finally(() => setIsLoading("done"))
  }

  useEffect(() => {
    if (email && !isValidEmail(email)) setError("Please enter a valid email address")
    else setError("")
  }, [email])

  return (
    <PassRecoveryWrapper>
      <PassRecoveryFormWrapper>
        <TitlePassRecoveryForm>Password recovery</TitlePassRecoveryForm>
        {error ? (
          <AlertStyled message="" description={error} type="error" showIcon />
        ) : null}
        <Input
          placeholder="Email"
          onChange={e => setEmail(e.target.value)}
          onPressEnter={() => !error && submitCredentials(email)}
          status={!error ? "" : "error"}
        />
        <ButtonStyled
          type="primary"
          onClick={() => submitCredentials(email)}
          disabled={!email || isLoading === "loading" || !!error}
          loading={isLoading === "loading"}
        >
          Submit
        </ButtonStyled>
        {isLoading === "done" && (
          <T.P2 style={{ marginTop: "30px", textAlign: "center" }}>
            If the email is correct you will receive a link to reset your
            password
          </T.P2>
        )}
      </PassRecoveryFormWrapper>
    </PassRecoveryWrapper>
  )
}

export default PasswordRecovery
