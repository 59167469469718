import styled from "styled-components"

export const SettingsCardsWrapper = styled.div`
  display: flex;
  gap: 16px;
  padding: 26px;
  flex-direction: row;
  flex-wrap: wrap;
`

