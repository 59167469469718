import { Spin } from "antd"
import axios from "axios"
import highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { colors } from "../../styles"
import { DelayDataType, DelaysDataResponseType } from "../../types"
import { AuthContext } from "../auth-context"
import {
  getSortedBrokers,
  getUniqueNonUsedBrokers,
  getUniquepairs,
  getUniqueUsedBrokers,
  reshapeDelaysData,
} from "./helpers"
// import { DelaysCardheader, DelaysCardWrapper, StyledButton } from "./styled"

import HighchartsHeatmap from "highcharts/modules/heatmap"
import {
  DelaysCardButtonWrapper,
  DelaysCardHeader,
  DelaysCardTitle,
  DelaysCardTitleWrapper,
  DelaysCardWrapper,
  StyledButton,
} from "./styled"
import { MAX_QUOTES_RATE_MS } from "../../constants"
HighchartsHeatmap(highcharts)

const url =
  (process.env.REACT_APP_ENDPOINT || "http://localhost:4000") +
  "/monitoring/delays"

const MonitoringDelaysCard = () => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)

  const { getCurrentUser } = useContext(AuthContext)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [delaysData, setDelaysData] = useState<DelayDataType>()

  const getDelaysData = useCallback(
    () =>
      getCurrentUser()
        .then(user => {
          if (!user.isLogged) return
          axios
            .get<DelaysDataResponseType>(url, {
              headers: {
                Authorization: user.tokens.token,
              },
            })
            .then(({ data }) => {
              const uniqueUsedBrokers = getUniqueUsedBrokers(data)
              const uniqueNonUsedBrokers = getUniqueNonUsedBrokers(data)
              const sortedBrokers = getSortedBrokers(
                uniqueUsedBrokers,
                uniqueNonUsedBrokers
              )

              const uniquePairs = getUniquepairs(data)

              const newDelaysData = reshapeDelaysData(
                data,
                uniquePairs,
                sortedBrokers
              )
              setDelaysData(newDelaysData)
            })
        })
        .catch(console.error),
    [getCurrentUser]
  )

  useEffect(() => {
    setIsLoading(true)
    getDelaysData().finally(() => setIsLoading(false))
  }, [getDelaysData])

  return (
    <DelaysCardWrapper>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <DelaysCardHeader>
            <DelaysCardTitleWrapper>
              <DelaysCardTitle>Time since latest quote</DelaysCardTitle>
            </DelaysCardTitleWrapper>
            <DelaysCardButtonWrapper>
              <StyledButton type="primary" onClick={getDelaysData}>
                Refresh
              </StyledButton>
            </DelaysCardButtonWrapper>
          </DelaysCardHeader>
          <HighchartsReact
            highcharts={highcharts}
            options={{
              accessibility: {
                enabled: false,
              },
              chart: {
                backgroundColor: colors.iUltraDarkGrey,
              },
              title: {
                floating: false,
                text: "",
              },
              series: [
                {
                  type: "heatmap",
                  data:
                    delaysData &&
                    delaysData.data.map(p => ({
                      x: p[0],
                      y: p[1],
                      value: p[2],
                      text: `${(p[2] / 1000).toFixed(1)}`,
                    })),
                  borderWidth: 1,
                  borderColor: colors.iDarkGrey,
                },
              ],
              xAxis: {
                categories: delaysData && delaysData.columns,
                labels: {
                  style: {
                    color: colors.iUltraLightGrey,
                  },
                },
              },
              yAxis: {
                categories: delaysData && delaysData.rows,
                title: null,
                labels: {
                  style: {
                    color: colors.iUltraLightGrey,
                  },
                },
                reversed: true,
              },
              colorAxis: {
                min: 0,
                max: MAX_QUOTES_RATE_MS,
                minColor: colors.iGreen,
                maxColor: colors.iRed,
                reversed: false,
                labels: {
                  formatter: function (this: any) {
                    if (this.value === undefined) return ""
                    return `${this.value / 1_000} s`
                  },
                  style: {
                    color: colors.iUltraLightGrey,
                  },
                },
              },
              legend: {
                align: "right",
                layout: "vertical",
                margin: 0,
                verticalAlign: "top",
                y: 25,
                symbolHeight: 280,
                title: {
                  text: "delay",
                  style: {
                    color: colors.iUltraLightGrey,
                  },
                },
              },
              tooltip: {
                formatter: function (this: any) {
                  if (!this || !delaysData) return ""
                  const { x, y, value } = this.point.options
                  const thePair = delaysData.columns.at(x)
                  const theBroker = delaysData.rows.at(y)
                  return `Last quote of <b>${thePair}</b> received from <b>${theBroker}</b> was <b>${(
                    value / 1000
                  ).toFixed(2)}s</b> ago.`
                },
              },
            }}
            ref={chartComponentRef}
            containerProps={{
              className: "chartContainer",
              style: {
                height: "100%",
                width: "100%",
              },
            }}
          />
        </>
      )}
    </DelaysCardWrapper>
  )
}

export default MonitoringDelaysCard
