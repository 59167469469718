import styled from "styled-components"
import { breakpointNum, colors } from "../../styles"
import * as T from "../typography"

export const StyledNotes = styled(T.H7)`
  color: ${colors.iMediumGrey};
  padding-top: 2px;
  @media (max-width: ${breakpointNum.tablet - 1}px) {
    padding-top: 2px;
  }
`