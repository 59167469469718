import styled from "styled-components"
import * as T from "../typography"
import { colors } from "../../styles"

export const CurrencyExposureWrapper = styled.div`
  display: flex;
  gap: 8px;
`

export const CurrencyExposureLeftWrapper = styled.div`
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;

  svg {
    overflow: visible;
  }
  .highcharts-container {
    overflow: visible !important;
  }
  .chartContainer{
    overflow: visible !important;
  }
`

export const CurrencyExposureRightWrapper = styled.div`
  width: 75%;
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.65fr 1fr;
  margin-top: 8px;
  margin-right: 3px;
`

export const VerticalLine = styled.div`
  height: 100%;
  margin-top: 8px;
  margin-bottom: auto;
  width: 1px;
  background-color: ${colors.iDarkGrey};
  align-self: center;
`

export const CurrencyExposureGridHeader = styled(T.Notes)`
  color: ${colors.iMediumGrey};
  text-align: right;
  :first-child {
    text-align: left;
  }
`
export const CurrencyExposureGridElement = styled(T.P3)<{
  negative?: boolean
  dot?: string
  isOpaque?: boolean
}>`
  color: ${({ negative }) =>
    negative === undefined
      ? colors.iWhite
      : negative
      ? colors.iRed
      : colors.iGreen};
  opacity: ${({ isOpaque }) => (isOpaque ? 1 : 0.5)};
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  text-align: ${({ dot }) => (dot ? "left" : "right")};
  ${({ dot, isOpaque }) =>
    dot &&
    `
    :after {
      margin-top: 1px;
      margin-right: 5px;  
      height: 12px;
      width: 12px;
      border-radius: 50%;
      float: left;
      background-color: ${dot};
      content: "";
      align-self: center;
      opacity: ${isOpaque ? "1" : "0.5"};
      transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }  
  `}
`
