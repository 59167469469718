import axios, { AxiosError } from "axios"
import { ReactSetter } from "../../types"

const checkPassword = (
  password: string,
  confirmPassword: string,
  setError: ReactSetter<string>
) => {
  if (password !== confirmPassword) {
    setError("The password and confirm password must match")
    return false
  }
  if (password.length < 8) {
    setError("The password must be at least 8 characters long")
    return false
  }
  return true
}

export const submitCredentials = async (
  email: string,
  password: string,
  confirmPassword: string,
  firstName: string,
  lastName: string,
  setIsLoading: ReactSetter<boolean>,
  setError: ReactSetter<string>,
  setSuccess: ReactSetter<string>,
  token: string
) => {
  setIsLoading(true)
  setError("")
  setSuccess("")

  if (
    email === "" ||
    password === "" ||
    confirmPassword === "" ||
    firstName === "" ||
    lastName === ""
  ) {
    setIsLoading(false)
    setError("All fields are required")
    return
  }

  if (!checkPassword(password, confirmPassword, setError)) {
    setIsLoading(false)
    setError("The confirm password field must match the password")
    return
  }

  axios
    .post(
      `${process.env.REACT_APP_ENDPOINT}/auth/signup`,
      {
        email,
        password,
        confirmPassword,
        firstName,
        lastName,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then(res => {
      if (!res) throw new Error("Request failed")
      setSuccess("Account created successfully")
    })
    .catch((err: Error | AxiosError) => {
      console.log("err = ", { err })
      if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError<{ message: string }>

        if (axiosError?.response?.status === 401) {
          setError("Unauthorized")
        } else {
          setError(axiosError?.response?.data?.message || "Unknown error")
        }
      } else setError(err.message)
    })

    .finally(() => setIsLoading(false))
}
