import { typedKeys } from "../../helpers"
import { ExposureStartingBalanceDataType } from "../../types"

export const parseReceivedData = (data: ExposureStartingBalanceDataType) => {
  const defaultvals = data.popBrokers.reduce(
    (acc, broker) => {
      return {
        ...acc,
        formKeys: {
          ...acc.formKeys,
          [`${broker}`]: 0,
        },
      }
    },
    {
      formKeys: {},
      depositAmount: 0,
    }
  )
  return typedKeys(data.popConf).filter(popBroker => data.popBrokers.includes(popBroker)).reduce((acc, popBroker) => {
    return {
      ...acc,
      formKeys: {
        ...acc.formKeys,
        [`${popBroker}`]: data.popConf[popBroker] ?? 0,
      },
      depositAmount: acc.depositAmount + data.popConf[popBroker],
    }
  }, defaultvals)
}
