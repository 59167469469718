import { getPairStrip } from "../../helpers"

const formatter = Intl.NumberFormat("en", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 3,
  minimumFractionDigits: 3,
})
export const formatExposure = (exposure: number) =>
  formatter.format(exposure / 1_000_000) + "m"

export const updateSelectBox =
  (pairsWithData: string[]) =>
  (oldOptions: { sortLabel: string; value: string; label: string }[]) =>
    [
      ...oldOptions,
      ...pairsWithData
        .flatMap(pair => {
          const pairStrip = getPairStrip(pair)
          const pairInCombo = oldOptions.find(x => x.value === pair)
          if (!pairInCombo) {
            return [
              {
                sortLabel: pairStrip === "SUM" ? "00_SUM" : pair,
                value: pair,
                label: pairStrip.toUpperCase(),
              },
            ]
          } else return []
        })
        .sort((a, b) => {
          const pair1 = a.sortLabel
          const pair2 = b.sortLabel
          if (pair1 < pair2) return -1
          if (pair1 > pair2) return 1
          return 0
        }),
    ]
