import { Radio, Select } from "antd"
import styled from "styled-components"
import { breakpointNum, colors, widgetLayouts } from "../../styles"

export const SubHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${colors.iBlack};
  border-top: 1px solid ${colors.iUltraLightGrey}29;
  border-bottom: 1px solid ${colors.iUltraLightGrey}29;
  justify-content: space-between;
  height: ${widgetLayouts.subHeaderHeightPx}px;
  overflow: hidden;
  @media (max-width: ${breakpointNum.tablet - 1}px) {
    height: ${widgetLayouts.subHeaderSmallHeightPx}px;
  }
  flex-shrink: 0;
`

export const LeftSubHeaderWrapper = styled.div`
  display: flex;
  flex-shrink: 1;
  & > :not(:last-child) {
    ::after {
      content: "";
      position: absolute;
      height: 50%;
      background: ${colors.iMediumGrey}26;
      top: 0%;
      width: 1px;
      right: -4px;
      z-index: 5;
      transform: translateY(50%);
    }
  }
`

export const RightSubHeaderWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
`

export const RightOptionTogglesWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  margin-right: 8px;
`

export const SelectStyled = styled(Select)`
  max-width: 110px;
  background-color: ${colors.iBlack};
  color: ${colors.iLightGrey};
  span {
    color: ${({ disabled }) => (disabled ? colors.iMediumGrey : colors.iWhite)};
    word-wrap: break-word;
    @media (max-width: ${breakpointNum.tablet - 1}px) {
      line-height: 18px !important;
    }
  }

  & > span {
    display: ${({ disabled }) => (disabled ? "none" : "")};
  }
  .ant-select-arrow {
    right: 2px;
    @media (max-width: ${breakpointNum.tablet - 1}px) {
      margin-top: -3px;
    }
  }
  .ant-select-selection-item {
    padding-left: 16px;
    padding-right: 12px !important;
    font-size: 11px;
    word-wrap: break-word;
    @media (max-width: ${breakpointNum.tablet - 1}px) {
      font-size: 10px;
    }
  }

  .ant-select-selector {
    min-width: 70px;
    padding: 0 8px !important;
    @media (max-width: ${breakpointNum.tablet - 1}px) {
      height: ${widgetLayouts.subHeaderSmallHeightPx - 10}px !important;
    }
  }
`
SelectStyled.defaultProps = {
  variant: "borderless",
  popupMatchSelectWidth: false,
}

export const OptionStyled = styled(Select.Option)``

export const GraphButtonCircle = styled.div<{ isActive?: boolean }>`
  border-radius: 50%;
  background-color: ${({ isActive }) =>
    isActive ? colors.iPrimaryBlue : colors.iDarkGrey};
  transition: background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: ${widgetLayouts.subHeaderRoundButtonDimPx}px;
  width: ${widgetLayouts.subHeaderRoundButtonDimPx}px;
  @media (max-width: ${breakpointNum.tablet - 1}px) {
    height: ${widgetLayouts.subHeaderRoundSmallButtonDimPx}px;
    width: ${widgetLayouts.subHeaderRoundSmallButtonDimPx}px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  :not(:last-child) {
    margin-right: 5px;
  }
  > * {
    width: 60%;
    height: 60%;
    cursor: pointer;
  }
  .icon {
    height: ${widgetLayouts.subHeaderRoundGraphIconDimPx}px;
    @media (max-width: ${breakpointNum.tablet - 1}px) {
      height: ${widgetLayouts.subHeaderRoundSmallGraphIconDimPx}px;
    }
    color: ${({ isActive }) =>
      isActive ? colors.iUltraLightGrey : colors.iMediumGrey};
    :hover {
      color: ${colors.iLightGrey};
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    > h6 {
      color: ${({ isActive }) =>
        isActive ? colors.iUltraLightGrey : colors.iMediumGrey};
      :hover {
        color: ${colors.iLightGrey};
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
  }
`

export const RadioStyled = styled(Radio)`
  color: ${colors.iWhite};
  margin: 0px;
  margin-left: 4px;

  .ant-radio {
    transform: scale(0.7);
  }
  .ant-radio-checked .ant-radio-inner {
      border-color: ${colors.iPrimaryBlue};
      background-color: ${colors.iWhite};
    }
  .ant-radio-inner {
    ::after {
      background-color: ${colors.iPrimaryBlue};
    }
  }
  span.ant-radio + * {
    font-size: 12px;
    padding: 0 4px;
  }
`
